import React from "react"
import styled from "styled-components"
import Image from "../../../Core/Image"
import RichText from "../../../Core/RichText"
import { Row, Col } from "styled-bootstrap-grid";
import { H3Style, H4Style } from "../../../Core/Styles/typography";
import breakpoints from "../../../../styles/breakpoints";
import colors from "../../../../styles/colors";
import { GatsbyImage } from "gatsby-plugin-image";


const Module = styled.div`
  max-width: 839px;
  @media (min-width: ${breakpoints.md}) {
    margin: auto;
  }
`;

const ImageContainer = styled.div`
  height: 100%;
  position: relative;
  display: flex;
  justify-content:center;
  align-items: center;
`

const StyledImage = styled(GatsbyImage)`
  img {
    width: 100%;
  }
  @media (min-width: ${breakpoints.md}) {
    border-radius: 20px;
    height: calc(100% + 60px);

    img {
      object-fit: ${props => props.objectFit ? props.objectFit : "cover"}!important;
    }

  }
`;

const MobileImage = styled(StyledImage)`
  display: block !important;
  @media (min-width: ${breakpoints.md}) {
    display: none !important;
  }
`;

const DesktopImage = styled(StyledImage)`
  display: none !important;
  @media (min-width: ${breakpoints.md}) {
    display: block !important;
  }
`;

const ContentCol = styled(Col)`
  padding: 0 26px;

  /* @media (min-width: ${breakpoints.md}) {
    padding: 0 38px;
  } */
  @media (min-width: ${breakpoints.md}) {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0;
  }
`;

const TextBlock = styled.div`
  background: ${colors.greyLight};
  padding: 24px 24px 58px;
  margin-top: -40px;
  border-radius: 10px;

  @media (min-width: ${breakpoints.md}) {
    min-width: 358px;
    padding: 40px 40px 70px;
    margin: auto;
    border-radius: 20px;
  }
`;

const Year = styled.p`
  ${H3Style};
  color: ${colors.greyDarker};
  margin-bottom: 6px;
`;

const Heading = styled.h3`
  ${H4Style};
  color: ${colors.greyDarker};
`;

const Text = styled.div`
  margin-top: 6px;
  color: ${colors.greyDark};
`;

const ImageCol = styled(Col)`
  @media (min-width: ${breakpoints.md}) {
    height: inherit;
  }
`

const StyledRow = styled(Row)`
  @media (min-width: ${breakpoints.md}) {
    padding: 30px 0;
  }
`


const TimelineEntry = ({year, title, content, mobileImage, desktopImage, containedImage}) => {
  return (
    <Module>
      <StyledRow >
        <ImageCol
          md={8}
        >
          <ImageContainer>
            <MobileImage
              image={mobileImage.gatsbyImageData}
              alt={mobileImage.description || title}
            />
            <DesktopImage
              image={desktopImage.gatsbyImageData}
              alt={desktopImage.description || title}
              objectFit={containedImage ? "contain" : "cover"}
            />
          </ImageContainer>
        </ImageCol>
        <ContentCol md={4} mdOrder="first">
          <TextBlock>
            {year && <Year>{year}</Year>}
            {title && <Heading>{title}</Heading>}
            <Text>
              <RichText content={content} />
            </Text>
          </TextBlock>
        </ContentCol>
      </StyledRow>
    </Module>
  );
}

export default TimelineEntry
