import React from "react";
import BlockContainer from "../../Core/Containers/BlockContainer";
import ContentContainer from "../../Core/Containers/ContentContainer";
import styled from "styled-components";
import breakpoints from "../../../styles/breakpoints";
import colors from "../../../styles/colors";
import Image from "../../Core/Image";
import { Link } from "gatsby";
import InView from "../../Core/InView";

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 16px;
  row-gap: 24px;

  .reveal {
    opacity: 0;
    transition: 1s all ease;
    transform: translateY(10px);
  }
  .reveal.active {
    opacity: 1;
    transform: translateY(0%);
  }

  @media (min-width: ${breakpoints.md}) {
    grid-template-columns: repeat(6, 1fr);
    gap: 24px;
  }
`;

const TitleSection = styled.div`
  margin-bottom: 24px;

  .reveal {
    opacity: 0;
    transition: 1s all ease;
    transform: translateY(10px);
  }
  .reveal.active {
    opacity: 1;
    transform: translateY(0%);
  }

  @media (min-width: ${breakpoints.md}) {
    margin-bottom: 40px;
  }
`;

const Logo = styled.div`
  border-radius: 10px;
  border: 1px solid ${colors.greyLight};
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain !important;
    transition: all 0.3s ease !important;
  }
`;

const BrandsBlock = ({ brands }) => {
  return (
    <BlockContainer block>
      <ContentContainer>
        <TitleSection>
          <InView>
            <h2>Brands we work with</h2>
          </InView>
        </TitleSection>
        <Grid>
          {brands.map((brand, index) => {
            const { image } = brand;
            return (
              <InView key={index}>
                <Logo>
                  <Image image={image} />
                </Logo>
              </InView>
            );
          })}
        </Grid>
      </ContentContainer>
    </BlockContainer>
  );
};

export default BrandsBlock;
