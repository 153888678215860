import React from "react";
import BlockContainer from "../../Core/Containers/BlockContainer";
import styled from "styled-components";
import PromoModule from "../../Core/PromoModule";
import InView from "../../Core/InView";
import breakpoints from "../../../styles/breakpoints";

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 60px;

  .reveal {
    opacity: 0;
    transition: 1s all ease;
    transform: translateY(20px);

  }
  .reveal.active {
    opacity: 1;
    transform: translateY(0);
  }

  @media (min-width: ${breakpoints.md}) {
    .reveal {
      opacity: 0;
      transition: 1s all ease;

      :nth-child(even) {
        transform: translateX(2%);
      }
      :nth-child(odd) {
        transform: translateX(-2%);
      }
    }
    .reveal.active {
      opacity: 1;
      transform: translateX(0%);
    }
  }
`;



const IntroBlock = ({ blocks }) => {
  return (
    <BlockContainer block>
      <Grid>
        {blocks.map((promoModule, index) => {
          const { title, content, image, imagePosition } = promoModule;
          return (
            <InView key={index}>
              <PromoModule
                title={title}
                content={content}
                image={image}
                imagePosition={imagePosition}
                fluid
              />
            </InView>
          );
        })}
      </Grid>
    </BlockContainer>
  );
};

export default IntroBlock;
