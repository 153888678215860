import React from "react"
import styled from "styled-components"
import colors from "../../../../styles/colors"
import Image from "../../../Core/Image"
import { H4Style, H6Style } from "../../../Core/Styles/typography"

const Container = styled.div`
  img {
    border-radius: 10px;
  }
`

const ImageContainer = styled.div`
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 24px;
`


const Name = styled.p`
  ${H4Style}
`

const JobTitle = styled.p`
  ${H6Style}
  color: ${colors.greyMedium};
  margin-bottom: 4px;
`

const TeamMember = ({name, image, jobTitle}) => {
  return (
    <Container>
      <ImageContainer>
        <Image image={image} />
      </ImageContainer>
      <JobTitle>{jobTitle}</JobTitle>
      <Name>{name}</Name>
    </Container>
  )
}

export default TeamMember
