import React from "react"
import styled from "styled-components"
import { getRelativeLink, isInternalLink } from '../../services/core/links'
import { GatsbyImage } from "gatsby-plugin-image";
import { Row, Col } from "styled-bootstrap-grid";
import colors from "../../styles/colors"
import { H6Style } from "./Styles/typography"
import breakpoints from "../../styles/breakpoints"
import ArrowLink from "../Core/Buttons/ArrowLink"
import RichText from "./RichText"

const Module = styled.div`
  @media (min-width: ${breakpoints.lg}) {
    padding: 0 76px;
    margin: auto;
  }
`

const StyledImage = styled(GatsbyImage)`
  img {
    width: 100%;
  }
  height: ${props => props.fluid ? "auto" : "90vw;"};
  @media (min-width: ${breakpoints.lg}) {
    border-radius: 20px;
    height: 560px;
  }
`;


const ContentCol = styled(Col)`
  padding: 0 26px;

  @media (min-width: ${breakpoints.md}) {
    padding: 0 38px;
  }

  @media (min-width: ${breakpoints.lg}) {
    display: flex;
    align-items: center;
    justify-content: ${(props) => (props.isRight ? "flex-end" : "flex-start")};
    padding: 0;
  }
`;

const TextBlock = styled.div`
  background: ${colors.greyLight};
  padding: 24px 24px 58px;
  margin-top: -40px;
  border-radius: 10px;

  @media (min-width: ${breakpoints.lg}) {
    min-width: 455px;
    padding: 40px 40px 70px;
    margin: auto;
    border-radius: 20px;
  }
`

const Category = styled.p`
  ${H6Style};
  color: ${colors.greyMedium};
  margin-bottom: 6px;
`

const Heading = styled.h3`

`

const Text = styled.div`
  margin-top: 12px;
`

const LinkContainer = styled.div`
  margin-top: 24px;
`

const PromoModule = ({
  title = null, 
  category = null,
  content,
  linkText,
  linkUrl,
  imagePosition,
  image,
  fluid = false
}) => {
  return (
    <Module>
      <Row alignItems="center">
        <Col lg={10} lgOrder={imagePosition === "Left" ? "first" : "last"} xl={8.5}>
          <StyledImage image={image.gatsbyImageData} alt={image.description || title} fluid={fluid ? 1 : 0}/>
        </Col>
        <ContentCol lg={2} isRight={imagePosition === "Left" ? 1 : 0} xl={3.5}>
          <TextBlock>
            {category && <Category>{category}</Category>}
            {title && <Heading>{title}</Heading>}
            <Text>
              <RichText content={content} />
            </Text>
            {linkText && linkUrl && (
              <LinkContainer>
                {isInternalLink(linkUrl) ? (
                  <ArrowLink to={getRelativeLink(linkUrl)}>
                    {linkText}
                  </ArrowLink>
                ) : (
                  <ArrowLink href={linkUrl}>{linkText}</ArrowLink>
                )}
              </LinkContainer>
            )}
          </TextBlock>
        </ContentCol>
      </Row>
    </Module>
  );
}

export default PromoModule
