import React from "react";
import styled from "styled-components";
import breakpoints from "../../../styles/breakpoints";
import TeamMember from "./components/TeamMember";
import BlockContainer from "../../Core/Containers/BlockContainer";
import ContentContainer from "../../Core/Containers/ContentContainer";
import InView from "../../Core/InView";

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 24px;
  grid-row-gap: 58px;

  .reveal {
    opacity: 0;
    transition: 1s all ease;
    transform: translateY(10px);
  }
  .reveal.active {
    opacity: 1;
    transform: translateY(0%);
  }

  @media (max-width: ${breakpoints.md}) {
    grid-template-columns: 1fr 1fr;
  }
`;

const TitleSection = styled.div`
  margin-bottom: 40px;
  .reveal {
    opacity: 0;
    transition: 1s all ease;
    transform: translateY(10px);
  }
  .reveal.active {
    opacity: 1;
    transform: translateY(0%);
  }

  @media (min-width: ${breakpoints.md}) {
    margin-bottom: 40px;
  }
`;

const TeamBlock = ({ team }) => {
  return (
    <BlockContainer>
      <ContentContainer>
        <TitleSection>
          <InView>
            <h2>Meet the team</h2>
          </InView>
        </TitleSection>
        <Grid>
          {team.map((teamMember, index) => {
            const { jobTitle, name, photo } = teamMember;
            return (
              <InView key={index}>
                <TeamMember image={photo} name={name} jobTitle={jobTitle} />
              </InView>
            );
          })}
        </Grid>
      </ContentContainer>
    </BlockContainer>
  );
};

export default TeamBlock;
