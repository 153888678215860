import React, { useState, useRef } from "react";
import styled from "styled-components";
import breakpoints from "../../../styles/breakpoints";
import TimelineEntry from "./components/TimelineEntry";
import ContentContainer from "../../Core/Containers/ContentContainer";
import Slider from "react-slick";
import prevArrowUrl, {
  ReactComponent as PrevArrow,
} from "../../../resources/img/svg/arrows/chevron-arrow-left.svg";
import nextArrowUrl, {
  ReactComponent as NextArrow,
} from "../../../resources/img/svg/arrows/chevron-arrow-right.svg";
import circleArrow from "../../../resources/img/svg/arrows/circle-arrow-dark.svg";
import InView from "../../Core/InView";
import colors from "../../../styles/colors";

const Container = styled.div`
  margin-bottom: 60px;
  overflow: hidden;

  .reveal {
    opacity: 0;
    transition: 1s all ease;
    transform: translateY(20px);
  }
  .reveal.active {
    opacity: 1;
    transform: translateY(0);
  }

  @media (min-width: ${breakpoints.md}) {
    margin-bottom: 150px;
  }
`;

const TitleSection = styled.div`
  display: none;

  @media (min-width: ${breakpoints.md}) {
    display: block;
    margin-bottom: 40px;
  }
`;

const SliderContainer = styled.div`
  @media (min-width: ${breakpoints.md}) {
    max-width: ${breakpoints.xxl};
    margin: auto;
    overflow: visible;
    padding-left: 38px;
  }
  @media (min-width: ${breakpoints.lg}) {
    padding-left: 76px;
  }
`;

const StyledSlider = styled(Slider)`
  position: relative;
  @media (min-width: ${breakpoints.md}) {
    .slick-list {
      overflow: visible;
    }

    .slick-track {
      display: flex;
    }

    .slick-slide {
      margin-right: 60px;
    }
  }
  @media (min-width: ${breakpoints.lg}) {
    .slick-slide {
      margin-right: 120px;
    }
  }
`;

const Slide = styled.div`
  @media (min-width: ${breakpoints.md}) {
    opacity: ${(props) => (props.visible ? 1 : 0)};
    transition: opacity 0.5s ease;
  }
`;

const StyledButton = styled.button`
  background: transparent;
  border: none;
  position: absolute;
  z-index: 1;
  ${(props) => (props.next ? "right: 26px" : "left: 26px")};
  top: calc((50vw / 1.145) - 20px);
  cursor: pointer;
  img {
    display: none;
  }

  @media (min-width: ${breakpoints.md}) {
    opacity: ${(props) => (props.visible ? "1" : "0")};
    transition: opacity 0.5s ease;
    ${(props) => (props.next ? "right: 8px" : "left: -36px")};
    top: 50%;
    transform: translateY(-50%);
    svg {
      display: none;
    }
    img {
      display: block;
      width: 40px;
      ${(props) => !props.next && "transform: rotate(-180deg)"};
      transition: all 0.2s ease;
      :hover {
        transform: ${(props) =>
          props.next ? "scale(1.1)" : "rotate(-180deg) scale(1.1)"};
      }
    }
  }

  @media (min-width: ${breakpoints.lg}) {
    ${(props) => (props.next ? "right: 20px" : "left: -58px")};
    border-radius: 50%;
  }
`;

const ArrowButton = (props) => {
  const { onClick, next, visible } = props;
  return (
    <StyledButton
      onClick={onClick}
      next={next ? 1 : 0}
      visible={visible ? 1 : 0}
    >
      {next ? (
        <>
          <img src={circleArrow} alt={"next arrow"} />
          <NextArrow />
        </>
      ) : (
        <>
          <img src={circleArrow} alt={"previous arrow"} />
          <PrevArrow />
        </>
      )}
    </StyledButton>
  );
};

const Timeline = ({ timeline }) => {
  const [slideIndex, setSlideIndex] = useState(0);
  const sliderRef = useRef();
  const blockRef = useRef();

  const settings = {
    infinite: false,
    dots: false,
    variableWidth: true,
    autoplay: false,
    autoplaySpeed: 2500,
    cssEase: "linear",
    beforeChange: (current, next) => setSlideIndex(next),
    prevArrow: <ArrowButton visible={slideIndex !== 0} />,
    draggable: false,
    nextArrow: (
      <ArrowButton next visible={slideIndex !== timeline.length - 1} />
    ),
    responsive: [
      {
        breakpoint: 992,
        settings: {},
      },
      {
        breakpoint: 768,
        settings: {
          infinite: true,
          variableWidth: false,
          draggable: true,
        },
      },
    ],
  };

  return (
    <Container ref={blockRef}>
      <InView>
        <ContentContainer>
          <TitleSection>
            <h2>Timeline</h2>
          </TitleSection>
        </ContentContainer>
        <SliderContainer id={"slider-container"}>
          <StyledSlider {...settings} ref={sliderRef}>
            {timeline.map((entry, index) => {
              const {
                year,
                title,
                content,
                mobileImage,
                desktopImage,
                containedImage,
              } = entry;
              return (
                <Slide visible={index >= slideIndex ? 1 : 0}>
                  <TimelineEntry
                    year={year}
                    title={title}
                    content={content}
                    mobileImage={mobileImage}
                    desktopImage={desktopImage}
                    containedImage={containedImage}
                  />
                </Slide>
              );
            })}
          </StyledSlider>
        </SliderContainer>
      </InView>
    </Container>
  );
};

export default Timeline;
