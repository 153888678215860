import React from 'react'
import styled from "styled-components";
import { Link } from "gatsby"
import {ReactComponent as ArrowDark} from "../../../resources/img/svg/arrows/circle-arrow-dark.svg"
import {ReactComponent as ArrowLight} from "../../../resources/img/svg/arrows/circle-arrow-light.svg"
import { H5Style } from '../Styles/typography';
import colors from '../../../styles/colors';

const StyledLink = styled.div`
  ${H5Style};
  color: ${(props) => (props.light ? colors.white : colors.greyDark)};
  display: inline-block;

  a {
    display: flex;
    align-items: center;
    color: inherit;
    text-decoration: none;
  }
`;

const Icon = styled.div`
  margin-right: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const ArrowLink = ({to, href, target = "__blank", light, children}) => {

  const renderChildren = () => {
    return (
      <>
        <Icon>{light ? <ArrowLight /> : <ArrowDark/>}</Icon>
        <span>{children}</span>
      </>
    );
  }
  return (
    <StyledLink light={light ? 1 : 0}>
      {href ? <a href={href} target={target}>{renderChildren()}</a> : <Link to={to}>{renderChildren()}</Link>}
    </StyledLink>
  );
}

export default ArrowLink
