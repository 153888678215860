import React, { Fragment } from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import styled from "styled-components";
import breakpoints from "../../styles/breakpoints";
import fonts from "../../styles/fonts";
import BlockContainer from "../Core/Containers/BlockContainer";
import { Row, Col } from "styled-bootstrap-grid";

const HeroContainer = styled.div`
  padding: 0 25px;
  @media (min-width: ${breakpoints.md}) {
    padding: 0;
  }
`;

const DesktopImage = styled(GatsbyImage)`
  display: none !important;

  @media (min-width: ${breakpoints.md}) {
    display: block !important;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
  }

  @media (min-width: 1443px) {
    border-radius: 20px;
  }
`;

const MobileImage = styled(GatsbyImage)`
  display: block !important;
  margin-top: 60px;
  border-radius: 10px;

  @media (min-width: ${breakpoints.md}) {
    display: none !important;
  }
`;

const CopyCol = styled(Col)`
  @media (min-width: ${breakpoints.md}) {
    padding: ${(props) =>
      props.image ? "0 30px 0 25px" : "100px 30px 0 25px"};
  }
  @media (min-width: ${breakpoints.lg}) {
    padding: ${(props) =>
      props.image ? "0 63px 0 100px" : "100px 63px 0 76px"};
  }
`;

const SubTitle = styled.p`
  ${fonts.sourceSerif};
  font-size: 20px;
  line-height: 28px;
  margin-top: 24px;
  @media (min-width: ${breakpoints.md}) {
    margin-top: 18px;
    font-size: 24px;
    line-height: 33px;
  }
`;

const Hero = ({ image, className, text = null, imageMobile, subText}) => {
  return (
    <BlockContainer smMargin>
      <HeroContainer className={className}>
        <Row alignItems="center">
          <CopyCol md={image ? 6 : 8} lg={6}  image={image ? 1 : 0}>
            {text && <h1>{text}</h1>}
            {subText && (
              <SubTitle>
                {subText}
              </SubTitle>
            )}
          </CopyCol>
          <Col md={6} >
            {image && (
              <Fragment>
                <DesktopImage
                  image={image.gatsbyImageData}
                  alt={image.description || ""}
                />
                <MobileImage
                  image={
                    imageMobile
                      ? imageMobile.gatsbyImageData
                      : image.gatsbyImageData
                  }
                  alt={image.description || ""}
                />
              </Fragment>
            )}
          </Col>
        </Row>
      </HeroContainer>
    </BlockContainer>
  );
};

export default Hero;
