import React from "react"
import Layout from "../components/Layout/Layout"
import Seo from "../components/Core/Seo"
import { graphql } from "gatsby"
import styled from "styled-components"
import Timeline from "../components/About/Timeline"
import TeamBlock from "../components/About/TeamBlock"
import Hero from "../components/Core/Hero"
import IntroBlock from "../components/About/IntroBlock"
import breakpoints from "../styles/breakpoints"
import BrandsBlock from "../components/About/BrandsBlock"

const PageContainer = styled.div``

const HeroContainer = styled.div`
  @media (min-width: ${breakpoints.md}) {
    margin-bottom: 150px;
  }
`;

const AboutPage = (props) => {
  const { location, data } = props
  const path = location.pathname

  const page = data.contentfulAboutPage

  let sharingImage = false
  if (page.seo.socialMediaImage) {
    sharingImage = page.seo.socialMediaImage.file.url
  }

  return (
    <Layout>
      <Seo
        title={page.seo.metaTitle}
        description={page.seo.metaDescription}
        fullTitle={false}
        path={path}
        image={sharingImage}
      />
      <PageContainer>
        <HeroContainer>
          <Hero text={page.heading} subText={page.introText.introText} />
        </HeroContainer>
        <IntroBlock blocks={page.introBlock} />
        <BrandsBlock brands={page.brands} />
        <Timeline timeline={page.timeline} />
        <TeamBlock team={page.teamBlock} />
      </PageContainer>
    </Layout>
  )
}

export default AboutPage

export const aboutQuery = graphql`
  query AboutQuery {
    contentfulAboutPage(slug: { eq: "about" }) {
      brands {
        caseStudy {
          slug
        }
        image {
          gatsbyImageData
          description
        }
      }
      heading
      introText {
        introText
      }
      introBlock {
        ...promoModuleFragment
      }
      seo {
        ...seoFragment
      }
      teamBlock {
        jobTitle
        name
        photo {
          description
          gatsbyImageData(aspectRatio: 1)
        }
      }
      timeline {
        title
        content {
          raw
        }
        year
        mobileImage: image {
          description
          gatsbyImageData(width: 1400, aspectRatio: 1.145, quality: 100)
        }
        desktopImage: image {
          description
          gatsbyImageData(aspectRatio: 1, width: 1100, quality: 100)
        }
        containedImage
      }
    }
  }
`;
