import React, {  useRef, useEffect } from "react";
import { isBrowser } from "../../services/core/browser";

const InView = ({ children, once = false, style = null }) => {
  const domRef = useRef();
  useEffect(() => {
    reveal()
    window.addEventListener("scroll", reveal);
    return () => {
      window.removeEventListener("scroll", reveal);
    };
  });

  const reveal = () => {
    if (isBrowser()) {
      const windowHeight = window.innerHeight;
      const elementTop = domRef.current.getBoundingClientRect().top;
      const elementVisible = 150;

      if (elementTop < windowHeight - elementVisible) {
        domRef.current.classList.add("active");
      } else {
        if (!once) {
          domRef.current.classList.remove("active");
        }
      }
    }
  };

  return (
    <div ref={domRef} className={`reveal`} style={style}>
      {children}
    </div>
  );
};

export default InView;
